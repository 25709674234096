
// 获取面的最小外接矩形
import * as turf from '@turf/turf'

function getMinRect(points) {
    const line = turf.lineString(points);
    //计算中心点
    const center = turf.center(line);
    const options = { pivot: center };
    //生成凸多边形
    const hull = turf.convex(line);
    //console.info(hull,center,options)
    let minArea = 10000000000000000000
    let minBBoxAngle = 0
    let minBBox = null
    const coordinates = hull.geometry.coordinates[0]
    for (let i = 0; i < coordinates.length - 1; i++) {
        const point1 = turf.point(coordinates[i]);
        const point2 = turf.point(coordinates[i + 1]);
        const theta = turf.bearing(point1, point2);

        //旋转

        const rotatedPoly = turf.transformRotate(hull, theta, options);
        //计算面积
        const rotatebbox = turf.bbox(rotatedPoly);

        const rotatebboxBboxPolygon = turf.bboxPolygon(rotatebbox);

        const area = turf.area(rotatebboxBboxPolygon);

        console.log({ i, theta, area, minArea })

        if (area < minArea) {
            minArea = area
            minBBox = rotatebboxBboxPolygon
            minBBoxAngle = theta
        }


    }
    //将最小bbox旋转回去
    console.log("角度", -minBBoxAngle)
    const inverseRotatedPoly = turf.transformRotate(minBBox, -minBBoxAngle, options);
    console.log(inverseRotatedPoly)
    return inverseRotatedPoly.geometry.coordinates[0]
}

// console.info(getRect([
//     [
//         106.48322274621802,
//         29.612503373864804
//     ],
//     [
//         106.48404583581,
//         29.610842471422895
//     ],
//     [
//         106.48810185515002,
//         29.61140592617831
//     ],
//     [
//         106.49063843256131,
//         29.61303564281707
//     ],
//     [
//         106.488169835323,
//         29.613778642317673
//     ],
//     [
//         106.4852812307616,
//         29.614408325197076
//     ],
//     [
//         106.48374536281554,
//         29.613679563738458
//     ],
//     [
//         106.48322274621802,
//         29.612503373864804
//     ]
//   ]))
export default getMinRect;