
import JSZip from "jszip";
function loadKML(xml) {
    //let xml =await fetch("./waylines.wpml").then(r=>r.text())
    //console.info(xml);
    const parser = new DOMParser();
    window.doc = parser.parseFromString(xml, "text/xml");
    let plygon = doc.querySelectorAll("Placemark coordinates")
    //console.info(plygon[0].innerHTML)
    let xyzs = [...plygon].map(p => p.textContent.trim());
    //console.info(xyzs)
    return xyzs;
}

export default async function loadKMZ(blob) {

    const zip = new JSZip()
    const zipData = await zip.loadAsync(blob)
    console.info(zipData)
    let x1 = await zipData.files["wpmz/template.kml"].async("text")

    //console.info(x1,x2)
    console.groupCollapsed("面数据 template.kml")
    console.info(x1);
    let plygon = loadKML(x1);
    console.info(plygon)
    console.groupEnd()

    console.info("面坐标:", plygon[0].split("\n").map(x => x.trim()).map(x => x.split(",")));
    plygon = plygon[0].split("\n").map(x => x.trim()).map(x => x.split(","));

    console.groupCollapsed("点数据 waylines.wpml")
    let x2 = await zipData.files["wpmz/waylines.wpml"].async("text")
    console.info(x2);
    let pts = loadKML(x2)
    console.info(pts)
    console.groupEnd()

    console.info("点坐标:", pts.map(x => x.split(",")))
    return { plygon, pts }
}