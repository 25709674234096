import './style.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import * as proj from 'ol/proj';
import XYZ from 'ol/source/XYZ.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import {Fill, Stroke, Style, Text} from 'ol/style.js';
import Polygon from 'ol/geom/Polygon.js';
import Feature from 'ol/Feature.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import getMinRect from './getRect.mjs'

import wgs84ToGcj02 from './gcj02.js';
import loadKMZ from './kmz.js';
import { load } from 'ol/Image.js';

const countryStyle = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.6)',
  }),
  stroke: new Stroke({
    color: '#319FD3',
    width: 1,
  }),
});

const gaodeMapLayer = new TileLayer({
  title: "高德地图",
  source: new XYZ({
      url: 'http://wprd0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=7&x={x}&y={y}&z={z}',
      wrapX: false
  })
});


const map = new Map({
  target: 'map',
  layers: [
    gaodeMapLayer
  ],
  view: new View({
    center: proj.transform([106.487, 29.612], 'EPSG:4326', 'EPSG:3857'),
    zoom: 16,
    minZoom:1
  })
});

const plXys = [
  [
      106.48322274621802,
      29.612503373864804
  ],
  [
      106.48404583581,
      29.610842471422895
  ],
  [
      106.48810185515002,
      29.61140592617831
  ],
  [
      106.49063843256131,
      29.61303564281707
  ],
  [
      106.488169835323,
      29.613778642317673
  ],
  [
      106.4852812307616,
      29.614408325197076
  ],
  [
      106.48374536281554,
      29.613679563738458
  ],
  [
      106.48322274621802,
      29.612503373864804
  ]
]

const rect = getMinRect(plXys);
const rectXY = rect.map(xy => proj.transform(wgs84ToGcj02(xy), 'EPSG:4326', 'EPSG:3857'));

const coors = plXys.map(xy => proj.transform(wgs84ToGcj02(xy), 'EPSG:4326', 'EPSG:3857'));
console.info(coors)
// 创建Polygon Feature
var polygonFeature = new Feature({
  geometry: new Polygon([coors])
});
var polygonRect = new Feature({
  geometry: new Polygon([rectXY])
});

let source = new VectorSource({
  features:[polygonFeature,polygonRect]
});
let layer = new VectorLayer({
  source: source,
  style:countryStyle
});
map.addLayer(layer);


document.getElementById('uploadFileBtn').addEventListener('click', function() {
  document.getElementById('uploadFile').click(); // 当按钮被点击时，触发隐藏的文件输入框的点击事件
});
document.getElementById('uploadFile').addEventListener('change', function(event) {
  var file = event.target.files[0]; // 获取选中的文件
  if (file) {
      alert('您选择了文件: ' + file.name); // 显示文件名给用户
      loadKMZ(file).then(kmz => {
          console.info(kmz)
          //source.addFeatures(kmz.features);
      })
      // 在这里，你可以进一步处理文件，比如读取文件内容等
  }
});